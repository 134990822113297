import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useContext, useState } from "react";
import HomeScreen from "../../screens/HomeScreen";
import ProductScreen from "../../screens/ProductScreen";
import CartScreen from "../../screens/CartScreen";
import Navigation from "../Layout/Navigation";
import SigninScreen from "../../screens/SigninScreen";
import SignupScreen from "../../screens/SignupScreen";
import ProfileScreen from "../../screens/ProfileScreen";
import SearchScreen from '../../screens/SearchScreen';
import AdminRoute from "../AdminRoute";
import ProtectedRoute from "../ProtectedRoute";
import DashboardScreen from "../../screens/DashboardScreen";
import UserListScreen from "../../screens/UserListScreen";
import UserEditScreen from "../../screens/UserEditScreen";
import ProductListScreen from "../../screens/ProductListScreen";
import ProductEditScreen from "../../screens/ProductEditScreen";
import ShippingAddressScreen from "../../screens/ShippingAddressScreen";
import MapScreen from "../../screens/MapScreen";
import PaymentMethodScreen from "../../screens/PaymentMethodScreen";
import PlaceOrderScreen from "../../screens/PlaceOrderScreen";
import OrderScreen from "../../screens/OrderScreen";
import OrderHistoryScreen from "../../screens/OrderHistoryScreen";
import OrderListScreen from "../../screens/OrderListScreen";
import { Store } from "../../Store";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
const App = (props) => {
  const { state } = useContext(Store);
  const { fullBox } = state;
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  return (
    <div className="bg-zinc-800  text-white">
     
        <BrowserRouter>
        <div
          className={
            sideDrawerOpen
              ? fullBox
                ? "site-container active-cont d-flex flex-col full-box "
                : "site-container active-cont d-flex flex-col "
              : fullBox
              ? "site-container d-flex flex-col full-box "
              : "site-container d-flex flex-col"
          }
        >
          <div className="fixed-top w-full z-1  ">
          <div className=" absolute inset-y-1 left-0  z-1">
            <DrawerToggleButton
              click={() => setSideDrawerOpen(!sideDrawerOpen)}
            />
          </div>
            <Navigation sideDrawerOpen={sideDrawerOpen}/>

            <main className="min-h-screen ">
              <Routes>
                <Route path="/product/:slug" element={<ProductScreen />} />
                <Route path="/cart" element={<CartScreen />} />
                <Route path="/search" element={<SearchScreen />} />
                <Route path="/signin" element={<SigninScreen />} />
                <Route path="/signup" element={<SignupScreen />} />
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute>
                      <ProfileScreen />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/dashboard"
                  element={
                    <AdminRoute>
                      <DashboardScreen />
                    </AdminRoute>
                  }
                ></Route>
                <Route
                  path="/shipping"
                  element={<ShippingAddressScreen />}
                ></Route>
                <Route
                  path="/map"
                  element={
                    <ProtectedRoute>
                      <MapScreen />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/payment"
                  element={<PaymentMethodScreen />}
                ></Route>

                <Route path="/placeorder" element={<PlaceOrderScreen />} />
                <Route
                  path="/order/:id"
                  element={
                    <ProtectedRoute>
                      <OrderScreen />
                    </ProtectedRoute>
                  }
                ></Route>

                <Route
                  path="/orderhistory"
                  element={
                    <ProtectedRoute>
                      <OrderHistoryScreen />
                    </ProtectedRoute>
                  }
                ></Route>

                <Route
                  path="/admin/orders"
                  element={
                    <AdminRoute>
                      <OrderListScreen />
                    </AdminRoute>
                  }
                ></Route>

                <Route
                  path="/admin/products"
                  element={
                    <AdminRoute>
                      <ProductListScreen />
                    </AdminRoute>
                  }
                ></Route>
                <Route
                  path="/admin/product/:id"
                  element={
                    <AdminRoute>
                      <ProductEditScreen />
                    </AdminRoute>
                  }
                ></Route>
                <Route
                  path="/admin/users"
                  element={
                    <AdminRoute>
                      <UserListScreen />
                    </AdminRoute>
                  }
                ></Route>
                <Route
                  path="/admin/user/:id"
                  element={
                    <AdminRoute>
                      <UserEditScreen />
                    </AdminRoute>
                  }
                ></Route>

                <Route path="/" element={<HomeScreen />} />
              </Routes>
            </main>
          </div>
          </div>
        </BrowserRouter>
      </div>
  
  );
};

export default App;
