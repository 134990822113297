export default function Nav({ children }) {
    return (
      // <nav className="py-1 px-2 text-sm font-small  ">
      <nav>
        <div className="max-w-7x2 mx-auto bg-zinc-900 sm:px-4 md:px-6 lg:px-8 ">
          <div className=" flex  justify-between h-16">
           
              {children}
            
          </div>
        </div>
      </nav>
    );
  }
  