import Axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useContext, useEffect} from 'react';
import { Store } from '../Store';

import { getError } from '../utils';

export default function SigninScreen() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get('redirect');
  const redirect = redirectInUrl ? redirectInUrl : '/';
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;


  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  return (
    <div className="bg-zinc-900 p-10 mt-8 shadow-md shadow-black min-w-400 md:w-1/3 mx-auto">
        <header className="flex justify-between items-center py-4 text-white">
          <h3 className="text-xl">Signin</h3>
        </header>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          enableReinitialize
          validationSchema={Yup.object({
            email: Yup.string().required("Email is Required").email(),
            password: Yup.string().required("Password is Required"),
          })}
          onSubmit={async (values, actions) => {
            try {
              const { data } = await Axios.post('/api/users/signin', values);
              ctxDispatch({ type: 'USER_SIGNIN', payload: data });
              localStorage.setItem('userInfo', JSON.stringify(data));
              navigate(redirect || '/');
            } catch (err) {
              toast.error(getError(err));
            }
            actions.resetForm();
            actions.setSubmitting(false);
            
          }}
        >
          {({ isSubmitting, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
      
              <label
                htmlFor="email"
                className="text-sm block font-bold mb-2 text-gray-400"
              >
                Email address
              </label>
              <Field
                className="form-control   form-control:hover form-control:focus"
                placeholder="Write an email"
                name="email"
                // autoFocus
              />
              <ErrorMessage
                component="p"
                name="email"
                className="text-blue-600 text-sm"
              />

              <label
                htmlFor="password"
                className="text-sm block font-bold mb-2 text-gray-400"
              >
                Password
              </label>
              <Field
                name="password"
                id="password"
                placeholder="Write a password"
                className="form-control   form-control:hover form-control:focus"
              />
              <ErrorMessage
                component="p"
                name="password"
                className="text-blue-600 text-sm"
              />

    
              <button
                type="submit"
                className="btn btn-primary btn-primary:hover btn-primary:focus btn-primary:disabled ml-auto"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <AiOutlineLoading3Quarters className="animate-spin h-5 w-5" />
                ) : (
                  "Send"
                )}
              </button>

              <div className="m-5">
                <label className="text-sm block font-bold mb-2 text-gray-400">
                  New customer?{" "}
                </label>

                <Link
                   className="text-sm block font-bold mb-2 text-blue-600 hover:underline"
                  to={`/signup?navigate=${navigate}`}
                >
                  Create your account
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
  
  );
}
