import Axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getError } from "../utils";
import { Store } from "../Store";
import CheckoutSteps from "../components/CheckoutSteps";
import LoadingBox from "../components/LoadingBox";

const reducer = (state, action) => {
  switch (action.type) {
    case "CREATE_REQUEST":
      return { ...state, loading: true };
    case "CREATE_SUCCESS":
      return { ...state, loading: false };
    case "CREATE_FAIL":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default function PlaceOrderScreen() {
  const navigate = useNavigate();

  const [{ loading }, dispatch] = useReducer(reducer, {
    loading: false,
  });

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, userInfo } = state;

  const round2 = (num) => Math.round(num * 100 + Number.EPSILON) / 100; // 123.2345 => 123.23
  cart.itemsPrice = round2(
    cart.cartItems.reduce((a, c) => a + c.quantity * c.price, 0)
  );
  cart.shippingPrice = cart.itemsPrice > 100 ? round2(0) : round2(10);
  cart.taxPrice = round2(0.15 * cart.itemsPrice);
  cart.totalPrice = cart.itemsPrice + cart.shippingPrice + cart.taxPrice;

  const placeOrderHandler = async () => {
    try {
      dispatch({ type: "CREATE_REQUEST" });

      const { data } = await Axios.post(
        "/api/orders",
        {
          orderItems: cart.cartItems,
          shippingAddress: cart.shippingAddress,
          paymentMethod: cart.paymentMethod,
          itemsPrice: cart.itemsPrice,
          shippingPrice: cart.shippingPrice,
          taxPrice: cart.taxPrice,
          totalPrice: cart.totalPrice,
        },
        {
          headers: {
            authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      ctxDispatch({ type: "CART_CLEAR" });
      dispatch({ type: "CREATE_SUCCESS" });
      localStorage.removeItem("cartItems");
      navigate(`/order/${data.order._id}`);
    } catch (err) {
      dispatch({ type: "CREATE_FAIL" });
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    if (!cart.paymentMethod) {
      navigate("/payment");
    }
  }, [cart, navigate]);

  return (
    <div>
      <CheckoutSteps step1 step2 step3 step4></CheckoutSteps>

      <div className="bg-zinc-800 p-3  hadow-md shadow-black w-full mx-auto">
        <header className="flex justify-between items-center  text-white">
          <h3 className="text-xl">Preview Order</h3>
        </header>

        <div className="flex flex-col sm:flex-row md:flex-row  justify-around items-centers">
          <div className="flex flex-col justify-start  md:w-2/8 sm:w-2/8">
            <div className=" bg-zinc-700 text-white   w-full h-fit rounded-md shadow-md shadow-black p-6  text-start  my-4  ">
              <div className="flex justify-between items-center -inset-40 pb-2">
                <h2>Shipping</h2>
                <Link
                  className="font-bold text-orange-700 hover:text-orange-500 hover:underline"
                  to="/shipping"
                >
                  Edit
                </Link>
              </div>
              <p>
                <span>Name:</span> {cart.shippingAddress.fullName} <br />
                <span>Address: </span> {cart.shippingAddress.address},
                {cart.shippingAddress.city}, {cart.shippingAddress.postalCode},
                {cart.shippingAddress.country}
              </p>
            </div>

            <div className=" bg-zinc-700 text-white   w-full h-fit rounded-md shadow-md shadow-black p-6  text-start  my-1  ">
              <div className="flex justify-between items-center -inset-40 pb-2">
                <h2>Payment</h2>
                <Link
                  className="font-bold mx-auto  text-orange-700 hover:text-orange-500 hover:underline"
                  to="/payment"
                >
                  Edit
                </Link>
              </div>
              <div>
                {" "}
                <h5>
                  <span>Method:</span> {cart.paymentMethod}
                </h5>
              </div>
            </div>

            <div className=" bg-zinc-700 text-white w-min-fit  rounded-md shadow-md shadow-black  p-2  my-2  ">
              <div className="flex justify-between items-center -inset-40 pb-2">
                <h2> Cart Items</h2>
                <Link
                  className="font-bold   text-orange-700 hover:text-orange-500 hover:underline"
                  to="/cart"
                >
                  Edit
                </Link>
              </div>
              <div className="flex  items-center  ">
                <ul>
                  {cart.cartItems.map((item) => (
                    <li
                      key={item._id}
                      className=" h-100 bg-zinc-800 items-baseline  h-auto  rounded-sm shadow-md shadow-black "
                    >
                      <div className="flex flex-row items-center justify-around  p-2">
                        <img
                          src={item.image}
                          alt={item.name}
                          className="w-[30%] lg:w-[20%] rounded-sm border border-gray-600"
                        ></img>{" "}
                        <Link
                          className="text-blue-100 hover:text-blue-500 underline  font-semibold"
                          to={`/product/${item.slug}`}
                        >
                          {item.name}
                        </Link>
                        <div className="md:flex-col-3 mr-5">
                          <p>
                            {" "}
                            <span>qty:</span>
                            {item.quantity}
                          </p>
                        </div>
                        <div className="md:flex-col-3 mr-2">${item.price}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="md:w-2/5 sm:w-2/5  bg-zinc-700 text-white    rounded-md shadow-md shadow-black   p-6 h-fit mx-2 my-4 ">
            <div className="mb-4">
              <h2>Order Summary</h2>
            </div>
            <ul className="  bg-zinc-800 items-baseline  h-auto w-full rounded-sm shadow-md shadow-black p-5">
              <li>
                <div className="flex flex-row">
                  <div className="flex flex-col  ">Items:</div>
                  <div className="flex flex-col ml-14">
                    ${cart.itemsPrice.toFixed(2)}
                  </div>
                </div>
              </li>
              <li>
                <div className="flex flex-row">
                  <div className="flex flex-col ">Shipping:</div>
                  <div className="flex flex-col ml-12 ">
                    ${cart.shippingPrice.toFixed(2)}
                  </div>
                </div>
              </li>
              <li>
                <div className="flex flex-row">
                  <div className="flex flex-col ">Tax:</div>
                  <div className="flex flex-col ml-20 ">
                    ${cart.taxPrice.toFixed(2)}
                  </div>
                </div>
              </li>
              <li className="color-blue-light">
                <div className="flex flex-row">
                  <div className="flex flex-col ">
                    <span> Order Total:</span>
                  </div>
                  <div className="flex flex-col ml-2 ">
                    <span>${cart.totalPrice.toFixed(2)}</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-grid">
                  <button
                    type="button"
                    className="btn btn-primary btn-primary:hover btn-primary:focus btn-primary:disabled ml-auto"
                    onClick={placeOrderHandler}
                    disabled={cart.cartItems.length === 0}
                  >
                    Place Order
                  </button>
                </div>
                {loading && <LoadingBox></LoadingBox>}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
