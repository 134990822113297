import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Store } from '../Store';
import { getError } from '../utils';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';


const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'UPDATE_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_SUCCESS':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_FAIL':
      return { ...state, loadingUpdate: false };
    case 'UPLOAD_REQUEST':
      return { ...state, loadingUpload: true, errorUpload: '' };
    case 'UPLOAD_SUCCESS':
      return {
        ...state,
        loadingUpload: false,
        errorUpload: '',
      };
    case 'UPLOAD_FAIL':
      return { ...state, loadingUpload: false, errorUpload: action.payload };

    default:
      return state;
  }
};
export default function ProductEditScreen() {
  const navigate = useNavigate();
  const params = useParams(); // /product/:id
  const { id: productId } = params;

  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ loading, error, loadingUpdate, loadingUpload }, dispatch] =
    useReducer(reducer, {
      loading: true,
      error: '',
    });

  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [price, setPrice] = useState('');
  const [image, setImage] = useState('');
  const [images, setImages] = useState([]);
  const [category, setCategory] = useState('');
  const [countInStock, setCountInStock] = useState('');
  const [brand, setBrand] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(`/api/products/${productId}`);
        setName(data.name);
        setSlug(data.slug);
        setPrice(data.price);
        setImage(data.image);
        setImages(data.images);
        setCategory(data.category);
        setCountInStock(data.countInStock);
        setBrand(data.brand);
        setDescription(data.description);
        dispatch({ type: 'FETCH_SUCCESS' });
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(err),
        });
      }
    };
    fetchData();
  }, [productId]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      dispatch({ type: 'UPDATE_REQUEST' });
      await axios.put(
        `/api/products/${productId}`,
        {
          _id: productId,
          name,
          slug,
          price,
          image,
          images,
          category,
          brand,
          countInStock,
          description,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: 'UPDATE_SUCCESS',
      });
      toast.success('Product updated successfully');
      navigate('/admin/products');
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'UPDATE_FAIL' });
    }
  };
  const uploadFileHandler = async (e, forImages) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    try {
      dispatch({ type: 'UPLOAD_REQUEST' });
      const { data } = await axios.post('/api/upload', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `Bearer ${userInfo.token}`,
        },
      });
      dispatch({ type: 'UPLOAD_SUCCESS' });

      if (forImages) {
        setImages([...images, data.secure_url]);
      } else {
        setImage(data.secure_url);
      }
      toast.success('Image uploaded successfully. click Update to apply it');
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'UPLOAD_FAIL', payload: getError(err) });
    }
  };
  const deleteFileHandler = async (fileName, f) => {
    console.log(fileName, f);
    console.log(images);
    console.log(images.filter((x) => x !== fileName));
    setImages(images.filter((x) => x !== fileName));
    toast.success('Image removed successfully. click Update to apply it');
  };
  return (
    <div className="bg-zinc-800 p-10 mt-8 shadow-md shadow-black min-w-400 md:w-1/2 mx-auto">
    <header className="flex justify-between items-center py-4 text-white">
      <h3 className="text-xl">Edit Product {productId}</h3>
    </header>
  
    <div class="flex  min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div class="w-full max-w-md space-y-8">
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
     
            <form className="mt-8 space-y-6" onSubmit={submitHandler}>
      
      <div>
            <label className="text-sm block font-bold mb-2 text-gray-400">Name</label>
            <input className="form-control   form-control:hover form-control:focus"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
    </div>
            <label className="text-sm block font-bold mb-2 text-gray-400">Slug</label>
            <input className="form-control   form-control:hover form-control:focus"
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
              required
            />
       
       
            <label className="text-sm block font-bold mb-2 text-gray-400">Price</label>
            <input className="form-control   form-control:hover form-control:focus"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
     
     
            <label className="text-sm block font-bold mb-2 text-gray-400">Image File</label>
            <input className="form-control   form-control:hover form-control:focus"
              value={image}
              onChange={(e) => setImage(e.target.value)}
              required
            />
    
    
            <label className="text-sm block font-bold mb-2 text-gray-400">Upload Image</label>
            <input className="form-control   form-control:hover form-control:focus" type="file" onChange={uploadFileHandler} />
            {loadingUpload && <LoadingBox></LoadingBox>}
      

            <label className="text-sm block font-bold mb-2 text-gray-400">Additional Images</label>
            {images.length === 0 && <MessageBox>No image</MessageBox>}
            <ul>
              {images.map((x) => (
                <li key={x}>
                  {x}
                  <button variant="light" onClick={() => deleteFileHandler(x)}>
                    <i className="fa fa-times-circle"></i>
                  </button>
                </li>
              ))}
            </ul>
      
            <label className="text-sm block font-bold mb-2 text-gray-400">Upload Aditional Image</label>
            <input className="form-control   form-control:hover form-control:focus"
              type="file"
              onChange={(e) => uploadFileHandler(e, true)}
            />
            {loadingUpload && <LoadingBox></LoadingBox>}
      

            <label className="text-sm block font-bold mb-2 text-gray-400">Category</label>
            <input className="form-control   form-control:hover form-control:focus"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            />
        
            <label className="text-sm block font-bold mb-2 text-gray-400">Brand</label>
            <input className="form-control   form-control:hover form-control:focus"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              required
            />
       
            <label className="text-sm block font-bold mb-2 text-gray-400">Count In Stock</label>
            <input className="form-control   form-control:hover form-control:focus"
              value={countInStock}
              onChange={(e) => setCountInStock(e.target.value)}
              required
            />
     
            <label className="text-sm block font-bold mb-2 text-gray-400">Description</label>
            <input className="form-control   form-control:hover form-control:focus"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
       
            <button disabled={loadingUpdate} type="submit"  className="btn btn-primary btn-primary:hover btn-primary:focus btn-primary:disabled ">
              Update
            </button>
            {loadingUpdate && <LoadingBox></LoadingBox>}
    
        </form>
        
      )}
    </div>
    </div>
    </div>
  );
}
