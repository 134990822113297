import React from 'react';


export default function CheckoutSteps(props) {
  return (
    <div className="flex flex-row m-1 border text-blue-400 p-1 border-blue-600 items-center justify-around">
      <div className={props.step1 ? 'active p-1' : ' p-1'}>Sign-In</div>
      <div className={props.step2 ? 'active p-1' : ' p-1'}>Shipping</div>
      <div className={props.step3 ? 'active p-1' : ' p-1'}>Payment</div>
      <div className={props.step4 ? 'active p-1' : ' p-1'}>Place Order</div>
    </div>
  );
}
