import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CheckoutSteps from "../components/CheckoutSteps";
import { Store } from "../Store";

export default function PaymentMethodScreen() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { shippingAddress, paymentMethod },
  } = state;

  const [paymentMethodName, setPaymentMethod] = useState(
    paymentMethod || "PayPal"
  );

  useEffect(() => {
    if (!shippingAddress.address) {
      navigate("/shipping");
    }
  }, [shippingAddress, navigate]);
  const submitHandler = (e) => {
    e.preventDefault();
    ctxDispatch({ type: "SAVE_PAYMENT_METHOD", payload: paymentMethodName });
    localStorage.setItem("paymentMethod", paymentMethodName);
    console.log('paymentMethod', paymentMethod)
    navigate("/placeorder");
  };
  return (
    <div>
      <CheckoutSteps step1 step2 step3></CheckoutSteps>

      <div className="bg-zinc-800 p-10 mt-8 shadow-md shadow-black min-w-400 md:w-1/3 mx-auto">
        <header className="flex justify-between items-center py-4 text-white">
          <h3 className="text-xl">Payment Method</h3>
        </header>

        <form
          className="form-horizontal form-group "
          onSubmit={submitHandler}
        >
          <div className="flex items-center mb-4">
            <input
              type="radio"
              id="PayPal"            
              value="PayPal"
              className="w-4 h-4 text-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 "
              checked={paymentMethodName === "PayPal"}
              onChange={(e) => setPaymentMethod(e.target.value)}
            ></input>
            <label
              htmlFor="PayPal"
              className="ml-2 text-sm font-medium text-white"
            >
              PayPal
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              id="Stripe"            
              value="Stripe"
              className="w-4 h-4 text-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-600  focus:ring-2 "
              checked={paymentMethodName === "Stripe"}
              onChange={(e) => setPaymentMethod(e.target.value)}
            ></input>
            <label
              htmlFor="Stripe"
              className="ml-2 text-sm font-medium text-white"
            >
              Stripe
            </label>
          </div>

          <div className="mb-3">
            <button
              className="btn btn-primary btn-primary:hover btn-primary:focus btn-primary:disabled ml-auto"
              type="submit"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
