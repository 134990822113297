import { useContext } from 'react';
import { Store } from '../Store';


import MessageBox from '../components/MessageBox';

import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function CartScreen() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { cartItems },
  } = state;

  const updateCartHandler = async (item, quantity) => {
    const { data } = await axios.get(`/api/products/${item._id}`);
    if (data.countInStock < quantity) {
      window.alert('Sorry. Product is out of stock');
      return;
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...item, quantity },
    });
  };
  const removeItemHandler = (item) => {
    ctxDispatch({ type: 'CART_REMOVE_ITEM', payload: item });
  };

  const checkoutHandler = () => {
    navigate('/signin?redirect=/shipping');
  };

  return (
    <div class="mx-auto max-w-2xl py-8 px-2 sm:py-12 sm:px-3 md:py-6  md:px-3 lg:max-w-6xl lg:px-6">
    <header className="flex   text-white">
      <h3 className="text-xl">Shopping Cart</h3>
    </header>
      
      <div className="md:flex md:flex-row sm:flex sm:flex-row">
        <div className="flex flex-row items-baseline justify-around  p-2">
          {cartItems.length === 0 ? (
            <MessageBox>
              Cart is empty. <Link to="/">Go Shopping</Link>
            </MessageBox>
          ) : (
            <ul>
              {cartItems.map((item) => (
                <li key={item._id} className=" h-100 bg-zinc-800 items-baseline   m-3 rounded-sm shadow-md shadow-black ">
                    <div className="flex justify-between items-center -inset-40 pb-2">
                    <div className="md:flex md:flex-row items-center   p-2">
                      <img
                        src={item.image}
                        alt={item.name}
                        className="w-[50%] mr-3 lg:w-[30%] rounded-sm border border-gray-600"
                       
                      ></img>{' '}
                   <Link className="text-blue-100 hover:text-blue-500 underline text-xl font-semibold" to={`/product/${item.slug}`}>{item.name}</Link>
                    </div>
                    <div className="inline-flex p-5 ">
                      <button className="px-2 py-1 mr-3  btn-secondary"
                        onClick={() =>
                          updateCartHandler(item, item.quantity - 1)
                        }
                    
                        disabled={item.quantity === 1}
                      >
                        <i className="fas fa-minus-circle"></i>
                      </button>{' '}
                      <span>{item.quantity}</span>{' '}
                      <button className="px-2 py-1 mr-3 ml-3 btn-primary"
                       
                        onClick={() =>
                          updateCartHandler(item, item.quantity + 1)
                        }
                        disabled={item.quantity === item.countInStock}
                      >
                        <i className="fas fa-plus-circle"></i>
                      </button>
                    </div>
                    <div className="md:flex-col-3">${item.price}</div>
                    <div className="md:flex-col-2">
                      <button className="px-2 py-1 btn-danger mr-3 ml-3"
                        onClick={() => removeItemHandler(item)}
                       
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      
        <div class="flex flex-row items-baseline justify-around  px-6 ">
           
              <ul className="p-4 w-max-full bg-zinc-800 rounded-sm shadow-md shadow-black">
                <li >
                  <h2>
                    Subtotal ({cartItems.reduce((a, c) => a + c.quantity, 0)}{' '}
                    items) : $
                    {cartItems.reduce((a, c) => a + c.price * c.quantity, 0)}
                  </h2>
                </li>
                <li>
                  <div className="inline-block">
                    <button className="btn btn-primary btn-primary:hover btn-primary:focus btn-primary:disabled"
                      type="button"
                      
                      onClick={checkoutHandler}
                      disabled={cartItems.length === 0}
                    >
                      Proceed to Checkout
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
    
 
  );
}
