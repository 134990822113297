import axios from "axios";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Rating from "../components/Rating";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { getError } from "../utils";
import { Store } from "../Store";

import { toast } from "react-toastify";

const reducer = (state, action) => {
  switch (action.type) {
    case "REFRESH_PRODUCT":
      return { ...state, product: action.payload };
    case "CREATE_REQUEST":
      return { ...state, loadingCreateReview: true };
    case "CREATE_SUCCESS":
      return { ...state, loadingCreateReview: false };
    case "CREATE_FAIL":
      return { ...state, loadingCreateReview: false };
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, product: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function ProductScreen() {
  let reviewsRef = useRef();

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const navigate = useNavigate();
  const params = useParams();
  const { slug } = params;

  const [{ loading, error, product, loadingCreateReview }, dispatch] =
    useReducer(reducer, {
      product: [],
      loading: true,
      error: "",
    });
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get(`/api/products/slug/${slug}`);
        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: getError(err) });
      }
    };
    fetchData();
  }, [slug]);

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, userInfo } = state;
  const addToCartHandler = async () => {
    const existItem = cart.cartItems.find((x) => x._id === product._id);
    const quantity = existItem ? existItem.quantity + 1 : 1;
    const { data } = await axios.get(`/api/products/${product._id}`);
    if (data.countInStock < quantity) {
      window.alert("Sorry. Product is out of stock");
      return;
    }
    ctxDispatch({
      type: "CART_ADD_ITEM",
      payload: { ...product, quantity },
    });
    navigate("/cart");
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!comment || !rating) {
      toast.error("Please enter comment and rating");
      return;
    }
    try {
      const { data } = await axios.post(
        `/api/products/${product._id}/reviews`,
        { rating, comment, name: userInfo.name },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      dispatch({
        type: "CREATE_SUCCESS",
      });
      toast.success("Review submitted successfully");
      product.reviews.unshift(data.review);
      product.numReviews = data.numReviews;
      product.rating = data.rating;
      dispatch({ type: "REFRESH_PRODUCT", payload: product });
      window.scrollTo({
        behavior: "smooth",
        top: reviewsRef.current.offsetTop,
      });
    } catch (error) {
      toast.error(getError(error));
      dispatch({ type: "CREATE_FAIL" });
    }
  };
  return loading ? (
    <LoadingBox />
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div className=" mx-4 my-2 min-h-screen  justify-between items-center p-5">
      <div className="grid sm:grid-cols-2 md:grid-cols-3  ">
        <div>
          <img
            className="rounded-sm object-cover mx-auto  md:min-w-lg w-full shadow-md shadow-black"
            src={product.image}
            alt={product.name}
          ></img>
        </div>
        <div className=" mx-10 my-4 ">
          <h1>{product.name}</h1>
          <label>Description:</label>
          <p>{product.description}</p>
          <p className="text-sm font-medium text-white">
            Price: ${product.price}
          </p>
          <Rating
            rating={product.rating}
            numReviews={product.numReviews}
          ></Rating>
        </div>
        <div className=" bg-zinc-700 text-white  max-w-20rem  w-fit h-fit rounded-md shadow-md shadow-black  hover:cursor-pointer p-6  text-end mx-2 my-2  ">
          <div>
            <span>Price:</span>${product.price}
          </div>

          <div>
            Status:{" "}
            {product.countInStock > 0 ? (
              <label bg="success">In Stock</label>
            ) : (
              <label bg="danger">Unavailable</label>
            )}
          </div>

          {product.countInStock > 0 && (
            <div>
              <button onClick={addToCartHandler} className="btn btn-primary btn-primary:hover btn-primary:focus btn-primary:disabled ml-auto">
                Add to Cart
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="mx-2 my-4">
        {product.reviews.length === 0 && (
          <MessageBox>There is no review</MessageBox>
        )}
      </div>
      <ul>
        {product.reviews.map((review) => (
          <li key={review._id} className="text-white">
            <strong>{review.name}</strong>
            <Rating rating={review.rating} caption=" "></Rating>
            <p>{review.createdAt?.substring(0, 10)}</p>
            <p>{review.comment}</p>
          </li>
        ))}
      </ul>
      <div className="my-3">
        {userInfo ? (
          <form onSubmit={submitHandler} className="mb-3 md:w-1/3 sm:w-1/2" controlid="rating">
            <h2>Write a customer review</h2>
        
              <h5>Rating</h5>
              <select
                className="bg-dark card-selector form-control mb-4 "
                aria-label="Rating"
                value={rating}
                onChange={(e) => setRating(e.target.value)}
              >
                <option value="">Select...</option>
                <option value="1">1- Poor</option>
                <option value="2">2- Fair</option>
                <option value="3">3- Good</option>
                <option value="4">4- Very good</option>
                <option value="5">5- Excelent</option>
              </select>
         
            <label
              controlid="floatingTextarea"
              label="Comments"
              className="mb-3"
            >
              <textarea
                className="form-control"
                placeholder="Leave a comment here"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </label>

            <div className="mb-3 ">
              <button disabled={loadingCreateReview} type="submit"  className="btn btn-primary btn-primary:hover btn-primary:focus btn-primary:disabled ">
                Submit
              </button>
              {loadingCreateReview && <LoadingBox></LoadingBox>}
            </div>
          </form>
        ) : (
          <MessageBox>
            Please{" "}
            <Link to={`/signin?redirect=/product/${product.slug}`}>
              Sign In
            </Link>{" "}
            to write a review
          </MessageBox>
        )}
      </div>
    </div>
  );
}
export default ProductScreen;
