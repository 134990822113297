import { Link } from "react-router-dom";
import Rating from "./Rating";
import axios from "axios";
import { useContext } from "react";
import { Store } from "../Store";

function Product(props) {
  const { product } = props;

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { cartItems },
  } = state;

  const addToCartHandler = async (item) => {
    const existItem = cartItems.find((x) => x._id === product._id);
    const quantity = existItem ? existItem.quantity + 1 : 1;
    const { data } = await axios.get(`/api/products/${item._id}`);
    if (data.countInStock < quantity) {
      window.alert("Sorry. Product is out of stock");
      return;
    }
    ctxDispatch({
      type: "CART_ADD_ITEM",
      payload: { ...item, quantity },
    });
  };

  return (
    <div className="bg-zinc-800 text-white   rounded-md shadow-md shadow-black  mx-auto">
      <div className="rounded-md  lg:aspect-none ">
        <Link to={`/product/${product.slug}`}>
          <img className="rounded-sm  hover:cursor-pointer" src={product.image} alt={product.name} />
        </Link>
      </div>

      <div className="m-3 flex justify-between">
        <Link to={`/product/${product.slug}`}>
          <h3 className="text-sm text-white">{product.name}</h3>
        </Link>
        <p className="text-sm font-medium text-white">${product.price}</p>
      </div>
      <div className="m-2">
        <Rating rating={product.rating} numReviews={product.numReviews} />
      </div>

      {product.countInStock === 0 ? (
        <button disabled>Out of stock</button>
      ) : (
        <button
          className="text-sm block font-bold m-3 ml-auto text-orange-500 hover:underline"
          onClick={() => addToCartHandler(product)}
        >
          Add to cart
        </button>
      )}
    </div>
  );
}
export default Product;
