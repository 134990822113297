import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { Store } from "../Store";
import { getError } from "../utils";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "DELETE_REQUEST":
      return { ...state, loadingDelete: true, successDelete: false };
    case "DELETE_SUCCESS":
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case "DELETE_FAIL":
      return { ...state, loadingDelete: false };
    case "DELETE_RESET":
      return { ...state, loadingDelete: false, successDelete: false };
    default:
      return state;
  }
};
export default function UserListScreen() {
  const navigate = useNavigate();
  const [{ loading, error, users, loadingDelete,  successDelete }, dispatch] =
    useReducer(reducer, {
      loading: true,
      error: "",
    });

  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data } = await axios.get(`/api/users`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: "FETCH_SUCCESS", payload: data });
      } catch (err) {
        dispatch({
          type: "FETCH_FAIL",
          payload: getError(err),
        });
      }
    };
    if (successDelete) {
      dispatch({ type: "DELETE_RESET" });
    } else {
      fetchData();
    }
  }, [userInfo, successDelete]);

  const deleteHandler = async (user) => {
    if (window.confirm("Are you sure to delete?")) {
      try {
        dispatch({ type: "DELETE_REQUEST" });
        await axios.delete(`/api/users/${user._id}`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        toast.success("user deleted successfully");
        dispatch({ type: "DELETE_SUCCESS" });
      } catch (error) {
        toast.error(getError(error));
        dispatch({
          type: "DELETE_FAIL",
        });
      }
    }
  };

  return (

 

      <div >
          <header className="flex justify-between items-center py-2 px-2 text-white">
        <h3 className="text-xl"><span><i className="fas fa-users" /> </span> Users </h3>
      </header>
      <div className="bg-zinc-800 mx-4 my-2 min-h-screen justify-between items-center p-5">
      {loadingDelete && <LoadingBox></LoadingBox>}
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
    
            <div className="flex justify-between items-center py-2  ">
              <div className="grid  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 w-full">
                {users &&
                  users.map((user) => (
                 
                      <div key={user._id} className="bg-zinc-900   rounded-md shadow-md shadow-black  ">
                        <h3 className="py-4 px-4"><span><i className="fas fa-user" /> </span> {user.name}</h3>
                        <p className="p-4 text-sm"> {user._id}</p>
                        <h4 className="py-2 px-4 text-sm"> {user.email}</h4>

                        <h6>
                          <span className="p-4"> is Admin:</span>
                          {user.isAdmin ? "YES" : "NO"}
                        </h6>
                        <div className="flex justify-between items-center p-2">
                          <button
                            className="font-medium text-green-700 m-1 dark:text-green-700 hover:underline"
                            onClick={() => navigate(`/admin/user/${user._id}`)}
                          >
                            Edit
                          </button>
                          <button
                            className="font-medium m-1 text-red-600 dark:text-red-500 hover:underline"
                            onClick={() => deleteHandler(user)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                  
                  ))}
              </div>
            </div>
        
        )}
      </div>
</div>
  );
}
