import React, { useContext, Fragment, useState } from "react";
import Nav from "./Nav.js";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Store } from "../../Store";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import SideDrawer from "../SideDrawer/SideDrawer";
import { Link } from "react-router-dom";
import Backdrop from "../Backdrop/Backdrop";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Toolbar(props) {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, userInfo } = state;

  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const backdropClickHandler = () => {
    setSideDrawerOpen(false);
  };

  let backdrop;

  if (sideDrawerOpen) {
    backdrop = <Backdrop click={backdropClickHandler} />;
  }
  const signoutHandler = () => {
    ctxDispatch({ type: "USER_SIGNOUT" });
    localStorage.removeItem("userInfo");
    localStorage.removeItem("shippingAddress");
    localStorage.removeItem("paymentMethod");
    navigate("/");
    //window.location.href = "/signin";
  };

  const authLinks = (
    <Menu as="div" className="absolute py-2 right-0  text-left">
      {userInfo && userInfo.isAdmin ? (
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-sm  bg-zinc-900 px-4 py-2 text-sm font-medium text-fuchsia-600 shadow-sm  focus:outline-none  hover:bg-zinc-900 hover:text-gray-100 ">
            <NavLink
              className=" px-3 py-2 rounded-md text-sm font-medium"
              to="/admin/dashboard"
            >
              <i className="fas fa-user" /> Admin, {userInfo.name}
            </NavLink>
            <DrawerToggleButton />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10  w-40 origin-top-right rounded-sm bg-zinc-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <NavLink
                      to="/admin/products"
                      className={classNames(
                        active ? "bg-zinc-900 text-gray-100" : "text-red-500",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Drawings
                    </NavLink>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <NavLink
                      to="/admin/orders"
                      className={classNames(
                        active
                          ? "bg-zinc-900 text-gray-100"
                          : "text-orange-500",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Orders
                    </NavLink>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <NavLink
                      to="/admin/users"
                      className={classNames(
                        active
                          ? "bg-zinc-900 text-gray-100"
                          : "text-yellow-500",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Users
                    </NavLink>
                  )}
                </Menu.Item>

                <Menu.Item>
                  {({ active }) => (
                    <NavLink
                      to="#!"
                      onClick={signoutHandler}
                      className={classNames(
                        active ? "bg-zinc-900 text-gray-100" : "text-gray-100",
                        "block w-full px-4 py-2 text-left text-sm"
                      )}
                    >
                      Sign out
                    </NavLink>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </div>
      ) : (
        userInfo && (
          <div>
            <Menu.Button className="inline-flex w-full justify-center rounded-sm  bg-zinc-900 px-4 py-2 text-sm font-medium text-fuchsia-600 shadow-sm  focus:outline-none  hover:bg-zinc-900 hover:text-gray-100 ">
              <NavLink
                className=" px-3 py-2 rounded-md text-sm font-medium"
                to="/profile"
              >
                <i className="fas fa-user" /> {userInfo.name}
              </NavLink>
              <DrawerToggleButton />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10  w-40 origin-top-right rounded-sm bg-zinc-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <NavLink
                        className={classNames(
                          active ? "bg-zinc-900 text-gray-100" : "text-red-500",
                          "block px-4 py-2 text-sm"
                        )}
                        to="/cart"
                      >
                        {cart.cartItems.length > 0 && (
                          <button
                            type="button"
                            className="inline-flex items-center px-3 py-2  text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-600 focus:ring-4 focus:outline-none  dark:bg-blue-600 dark:hover:bg-blue-900 dark:focus:ring-blue-800"
                          >
                            Cart
                            <span className="inline-flex justify-center items-center ml-2 w-4 h-4 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                              {cart.cartItems.reduce(
                                (a, c) => a + c.quantity,
                                0
                              )}
                            </span>
                          </button>
                        )}
                      </NavLink>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <NavLink
                        className={classNames(
                          active
                            ? "bg-zinc-900 text-gray-100"
                            : "text-orange-500",
                          "block px-4 py-2 text-sm"
                        )}
                        to="/orderhistory"
                      >
                        Order History
                      </NavLink>
                    )}
                  </Menu.Item>

                  <Menu.Item>
                    {({ active }) => (
                      <NavLink
                        to="#!"
                        onClick={signoutHandler}
                        className={classNames(
                          active
                            ? "bg-zinc-900 text-gray-100"
                            : "text-gray-100",
                          "block w-full px-4 py-2 text-left text-sm"
                        )}
                      >
                        Sign out
                      </NavLink>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </div>
        )
      )}
    </Menu>
  );
  const guestLinks = (
    <Menu as="div" className="absolute py-3 right-0  text-left">
      <Menu.Item>
        <NavLink
          className="text-yellow-400  hover:text-zinc-900 px-3 py-2 rounded-md text-sm font-medium"
          to="/cart"
        >
          {cart.cartItems.length > 0 && (
            <button
              type="button"
              className="inline-flex items-center px-4 py-2.5 text-sm font-medium text-center text-white bg-blue-700 rounded-sm hover:bg-blue-600 focus:ring-4 focus:outline-none  dark:bg-blue-600 dark:hover:bg-blue-900 dark:focus:ring-blue-800"
            >
              Cart
              <span className="inline-flex justify-center items-center ml-2 w-4 h-4 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                {cart.cartItems.reduce((a, c) => a + c.quantity, 0)}
              </span>
            </button>
          )}
        </NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink
          className="text-blue-500 hover:bg-zinc-600 hover:text-zinc-300 px-4 py-3 border-none text-sm font-medium rounded-sm mr-2"
          to="/signin"
        >
          Sign In
        </NavLink>
      </Menu.Item>
    </Menu>
  );

  return (
    <Nav>
        <div className=" absolute inset-y-1 left-0 flex items-start  z-1">
       
        {backdrop}
        <SideDrawer show={props.sideDrawerOpen} />
      </div>
      <div className=" flex  justify-tight  md:justify-start min-w-max  ">
        <Link className="flex-shrink-0 flex items-center ml-12" to="/">
          <img
            className="block  h-8 w-auto"
            src="/img/logoxxxs.png"
            alt="Qepbits"
          ></img>
          <p className="text-blue-500 hover:text-blue-400  px-3 py-2 rounded-md text-md font-medium">
            Qepbits.com.es
          </p>
        </Link>
        {userInfo ? authLinks : guestLinks}
        {false && <Link to="/" />}
      </div>
    </Nav>
  );
}
