import React, { useContext, useReducer, useState } from "react";
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { Store } from "../Store";
import { toast } from "react-toastify";
import { getError } from "../utils";
import axios from "axios";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_REQUEST":
      return { ...state, loadingUpdate: true };
    case "UPDATE_SUCCESS":
      return { ...state, loadingUpdate: false };
    case "UPDATE_FAIL":
      return { ...state, loadingUpdate: false };

    default:
      return state;
  }
};

export default function ProfileScreen() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [name, setName] = useState(userInfo.name);
  const [email, setEmail] = useState(userInfo.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [{ loadingUpdate, error }, dispatch] = useReducer(reducer, {
    loadingUpdate: false,
  });

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }
    try {
      const { data } = await axios.put(
        "/api/users/profile",
        {
          name,
          email,
          password,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: "UPDATE_SUCCESS",
      });
      ctxDispatch({ type: "USER_SIGNIN", payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));
      toast.success("User updated successfully");
    } catch (err) {
      dispatch({
        type: "FETCH_FAIL",
      });
      toast.error(getError(err));
    }
  };

  return (
    <div className="bg-zinc-800 p-10 mt-8 shadow-md shadow-black min-w-400 md:w-1/3 mx-auto">
      <header className="flex justify-between items-center py-4 text-white">
        <h3 className="text-xl"> Profile: {userInfo.name}</h3>
      </header>
      {loadingUpdate ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
      <form onSubmit={submitHandler}>
        <label className="text-sm block font-bold mb-2 text-gray-400">
          Name
        </label>
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="form-control   form-control:hover form-control:focus"
        />

        <label className="text-sm block font-bold mb-2 text-gray-400">
          Email
        </label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="form-control   form-control:hover form-control:focus"
        />

        <label className="text-sm block font-bold mb-2 text-gray-400">
          Password
        </label>
        <input
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          className="form-control   form-control:hover form-control:focus"
        />

        <label className="text-sm block font-bold mb-2 text-gray-400">
          Confirm Password
        </label>
        <input
          type="password"
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="form-control   form-control:hover form-control:focus"
        />

        <button
          className="btn btn-primary btn-primary:hover btn-primary:focus btn-primary:disabled ml-auto"
          type="submit"
        >
          Update
        </button>
      </form>
)}
    </div>
  );
}
