import { useEffect, useReducer} from 'react';
import axios from 'axios';
import logger from 'use-reducer-logger';

import Product from '../components/Product';

import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
// import data from '../data';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, products: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function HomeScreen() {
  const [{ loading, error, products }, dispatch] = useReducer(logger(reducer), {
    products: [],
    loading: true,
    error: '',
  });
  // const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const result = await axios.get('/api/products');
        dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: err.message });
      }

      // setProducts(result.data);
    };
    fetchData();
  }, []);
  return (
    
    <div >
    <header className="flex justify-between items-center py-2 px-2 text-white ">
    <h2 className="text-xl px-2 ">Drawings Store</h2>
  </header>
 
  <div className="bg-zinc-800 p-5">
        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
        
            <div className="grid sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5  w-fit gap-5 mx-auto">
            {products.map((product) => (
          
                <Product key={product._id} product={product}></Product>
             
            ))}
          </div>
        
        )}
      </div>
    </div>
  );
}
export default HomeScreen;
