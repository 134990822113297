import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { getError } from "../../utils";
const SideDrawer = (props) => {
  const { show } = props;
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await axios.get(`/api/products/categories`);
        setCategories(data);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchCategories();
  }, []);

  return (
    <div
      data-drawer-backdrop="true"
      className={`bg-zinc-900 top-14 left-0 w-38 absolute  p-4    h-auto z-3  ease-in-out duration-300 ${
        show ? "translate-x-0 " : "-translate-x-full "
      }`}
      tabIndex="-1"
      aria-labelledby="drawer-navigation-label"
    >

      {categories &&
        categories.map((category) => (
          <ul key={category}>
            <li className="mb-5">
              <NavLink
                className="text-blue-500 hover:bg-zinc-700 hover:text-zinc-200 px-4 py-3 w-full border-none text-sm font-medium rounded-sm mr-2"
                to={`/search?category=${category}`}
                /* onClick={() => setSidebarIsOpen(false)} */
              >
                {category}
              </NavLink>
            </li>
          </ul>
        ))}
    </div>
  );
};

export default SideDrawer;
