import axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { Store } from '../Store';
import { getError } from '../utils';
import { toast } from 'react-toastify';

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true, error: '' };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false, order: action.payload, error: '' };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'PAY_REQUEST':
      return { ...state, loadingPay: true };
    case 'PAY_SUCCESS':
      return { ...state, loadingPay: false, successPay: true };
    case 'PAY_FAIL':
      return { ...state, loadingPay: false };
    case 'PAY_RESET':
      return { ...state, loadingPay: false, successPay: false };

    case 'DELIVER_REQUEST':
      return { ...state, loadingDeliver: true };
    case 'DELIVER_SUCCESS':
      return { ...state, loadingDeliver: false, successDeliver: true };
    case 'DELIVER_FAIL':
      return { ...state, loadingDeliver: false };
    case 'DELIVER_RESET':
      return {
        ...state,
        loadingDeliver: false,
        successDeliver: false,
      };
    default:
      return state;
  }
}
export default function OrderScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;

  const params = useParams();
  const { id: orderId } = params;
  const navigate = useNavigate();

  const [
    {
      loading,
      error,
      order,
      successPay,
      loadingPay,
      loadingDeliver,
      successDeliver,
    },
    dispatch,
  ] = useReducer(reducer, {
    loading: true,
    order: {},
    error: '',
    successPay: false,
    loadingPay: false,
  });

  const [{ isPending }, paypalDispatch] = usePayPalScriptReducer();

  function createOrder(data, actions) {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: { value: order.totalPrice },
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  }

  function onApprove(data, actions) {
    return actions.order.capture().then(async function (details) {
      try {
        dispatch({ type: 'PAY_REQUEST' });
        const { data } = await axios.put(
          `/api/orders/${order._id}/pay`,
          details,
          {
            headers: { authorization: `Bearer ${userInfo.token}` },
          }
        );
        dispatch({ type: 'PAY_SUCCESS', payload: data });
        toast.success('Order is paid');
      } catch (err) {
        dispatch({ type: 'PAY_FAIL', payload: getError(err) });
        toast.error(getError(err));
      }
    });
  }
  function onError(err) {
    toast.error(getError(err));
  }

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(`/api/orders/${orderId}`, {
          headers: { authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
      }
    };

    if (!userInfo) {
      return navigate('/login');
    }
    if (
      !order._id ||
      successPay ||
      successDeliver ||
      (order._id && order._id !== orderId)
    ) {
      fetchOrder();
      if (successPay) {
        dispatch({ type: 'PAY_RESET' });
      }
      if (successDeliver) {
        dispatch({ type: 'DELIVER_RESET' });
      }
    } else {
      const loadPaypalScript = async () => {
        const { data: clientId } = await axios.get('/api/keys/paypal', {
          headers: { authorization: `Bearer ${userInfo.token}` },
        });
        paypalDispatch({
          type: 'resetOptions',
          value: {
            'client-id': clientId,
            currency: 'USD',
          },
        });
        paypalDispatch({ type: 'setLoadingStatus', value: 'pending' });
      };
      loadPaypalScript();
    }
  }, [
    order,
    userInfo,
    orderId,
    navigate,
    paypalDispatch,
    successPay,
    successDeliver,
  ]);

  async function deliverOrderHandler() {
    try {
      dispatch({ type: 'DELIVER_REQUEST' });
      const { data } = await axios.put(
        `/api/orders/${order._id}/deliver`,
        {},
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({ type: 'DELIVER_SUCCESS', payload: data });
      toast.success('Order is delivered');
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'DELIVER_FAIL' });
    }
  }

  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div className="bg-zinc-800 p-3  hadow-md shadow-black w-full mx-auto">
    <header className="flex justify-between items-center  text-white">
      <h3 className="text-xl">Order nº: <span>{orderId}</span></h3>
    </header>
   
    <div className="flex flex-col sm:flex-row md:flex-row  justify-around items-centers">
          <div className="flex flex-col justify-start  md:w-2/8 sm:w-2/8">
            <div className=" bg-zinc-700 text-white   w-full  rounded-md shadow-md shadow-black px-4  text-start  my-2 pb-4  ">
            <div className="flex justify-between items-center -inset-40 ">
                <h2>Shipping</h2>
                {order.isDelivered ? (
                <MessageBox variant="success">
                  Delivered at {order.deliveredAt}
                </MessageBox>
              ) : (
                <MessageBox variant="danger">Not Delivered</MessageBox>
              )}
              </div>
              <p>
                <span>Name:</span> {order.shippingAddress.fullName} <br />
                <span>Address: </span> {order.shippingAddress.address}, 
                {order.shippingAddress.city}, {order.shippingAddress.postalCode}
                ,{order.shippingAddress.country}
                &nbsp;
                {order.shippingAddress.location &&
                  order.shippingAddress.location.lat && (
                    <a
                      target="_new"
                      href={`https://maps.google.com?q=${order.shippingAddress.location.lat},${order.shippingAddress.location.lng}`}
                    >
                      Show On Map
                    </a>
                  )}
              </p>
             
            </div>
      
          <div className=" bg-zinc-700 text-white   w-full h-fit rounded-md shadow-md shadow-black py-4 px-4 text-start   ">
              <div className="flex justify-between items-center -inset-40 ">
                <h2>Payment</h2>
            
              {order.isPaid ? (
                <MessageBox variant="success">
                  Paid at {order.paidAt}
                </MessageBox>
              ) : (
                <MessageBox variant="danger">Not Paid</MessageBox>
              )}
            </div>
            <p>
                <span>Method:</span> {order.paymentMethod}
              </p>
          </div>

          <div className=" bg-zinc-700 text-white w-min-fit  rounded-md shadow-md shadow-black  px-4  my-2  ">
     
                <h2> Cart Items</h2>
            
    
                <div className="md:flex md:flex-row sm:flex sm:flex-row">
            <div className="flex flex-row items-start  justify-between  ">
                <ul>
                {order.orderItems.map((item) => (
                      <li
                      key={item._id}
                      className=" h-100 rounded-sm  w-full p-2 m-2"
                    >
                      <div className="flex flex-row items-center justify-around  ">
                        <img
                          src={item.image}
                          alt={item.name}
                          className="w-[30%] lg:w-[20%] rounded-sm border border-gray-600"
                        ></img>{' '}
                        <Link to={`/product/${item.slug}`}>{item.name}</Link>
                
                          <p>
                            {" "}
                            <span>qty:</span>
                        {item.quantity}</p>
                    ${item.price}</div>
                    </li>
                
                ))}
              </ul>
            </div>
          </div>
        </div>
       </div>
        <div className="md:w-2/5 sm:w-2/5  bg-zinc-700 text-white    rounded-md shadow-md shadow-black   p-6 h-fit mx-2 my-4 ">
            <div className="mb-4">
              <h2>Order Summary</h2>
            </div>
            <ul className="  bg-zinc-800 items-baseline  h-auto w-full rounded-sm shadow-md shadow-black p-5">
            <li>
                <div className="flex flex-row">
                  <div className="flex flex-col  ">Items:</div>
                  <div className="flex flex-col ml-14">
                    ${order.itemsPrice.toFixed(2)}
                  </div>
                  </div>
                </li>
                <li>
                <div className="flex flex-row">
                  <div className="flex flex-col ">Shipping:</div>
                  <div className="flex flex-col ml-10 ">
                    ${order.shippingPrice.toFixed(2)}
                  </div>
                  </div>
                </li>
                <li>
                <div className="flex flex-row">
                  <div className="flex flex-col ">Tax:</div>
                  <div className="flex flex-col ml-20 ">
                    ${order.taxPrice.toFixed(2)}
                  </div>
                  </div>
                </li>
                <li className="color-blue-light">
                <div className="flex flex-row">
                  <div className="flex flex-col ">
                    <span> Order Total:</span>
                  </div>
                  <div className="flex flex-col ml-2 ">
                      <span>${order.totalPrice.toFixed(2)}</span>
                    </div>
                  </div>
                </li>
                {!order.isPaid && (
                  <li>
                    {isPending ? (
                      <LoadingBox />
                    ) : (
                      <div>
                        <PayPalButtons
                          createOrder={createOrder}
                          onApprove={onApprove}
                          onError={onError}
                        ></PayPalButtons>
                      </div>
                    )}
                    {loadingPay && <LoadingBox></LoadingBox>}
                  </li>
                )}
                {userInfo.isAdmin && order.isPaid && !order.isDelivered && (
                  <li>
                    {loadingDeliver && <LoadingBox></LoadingBox>}
                    <div className="d-grid">
                      <button type="button" 
                       className="btn btn-primary btn-primary:hover btn-primary:focus btn-primary:disabled ml-auto" onClick={deliverOrderHandler}>
                        Deliver Order
                      </button>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>

  );
}
