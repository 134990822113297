import Axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useContext, useEffect } from 'react';
import { Store } from '../Store';
import { toast, Toaster } from "react-hot-toast";
import { getError } from '../utils';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
export default function SignupScreen() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get('redirect');
  const redirect = redirectInUrl ? redirectInUrl : '/';

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;


  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  return (
    <div className="bg-zinc-900 p-10 mt-8 shadow-md shadow-black md:w-1/3 mx-auto">
    <header className="flex justify-between items-center py-4 text-white">
      <h3 className="text-xl">Create Account</h3>
    </header>
    <Toaster />
    <Formik
      initialValues={{
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
      }}
      enableReinitialize
      validationSchema={Yup.object({
        name: Yup.string().required("Name is Required"),
        email: Yup.string().required("Email is Required").email(),
        password: Yup.string().required("Password is Required"),
        confirmPassword: Yup.string().required(
          " Confirm password  is Required"
        ),
      })}
      onSubmit={async (values, actions) => {
        const { password, confirmPassword } = values;
        if (password === confirmPassword) {
          try {
            const { data } = await Axios.post('/api/users/signup', 
            values);
            ctxDispatch({ type: 'USER_SIGNIN', payload: data });
            localStorage.setItem('userInfo', JSON.stringify(data));
            navigate(redirect || '/');
          } catch (err) {
            toast.error(getError(err));
          }
        } else {
          toast.error("Passwords don't matches");
        }
        actions.resetForm();
        actions.setSubmitting(false);
        
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <label
            htmlFor="name"
            className="text-sm block font-bold mb-2 text-gray-400"
          >
            Name
          </label>
          <Field
            className="form-control   form-control:hover form-control:focus"
            placeholder="Write a name"
            id="name"
            name="name"
          />
          <ErrorMessage
            component="p"
            name="name"
            className="text-blue-600 text-sm"
          />
          <label
            htmlFor="email"
            className="text-sm block font-bold mb-2 text-gray-400"
          >
            Email address
          </label>
          <Field
            className="form-control   form-control:hover form-control:focus"
            placeholder="Write an email"
            name="email"
            // autoFocus
          />
          <ErrorMessage
            component="p"
            name="email"
            className="text-blue-600 text-sm"
          />
          <label
            htmlFor="password"
            className="text-sm block font-bold mb-2 text-gray-400"
          >
            Password
          </label>
          <Field
            name="password"
            id="password"
            placeholder="Write a password"
            className="form-control   form-control:hover form-control:focus"
          />
          <ErrorMessage
            component="p"
            name="password"
            className="text-blue-600 text-sm"
          />

          <label
            htmlFor="confirmPassword"
            className="text-sm block font-bold mb-2 text-gray-400 "
          >
            Confirm Password
          </label>
          <Field
            className="form-control   form-control:hover form-control:focus"
            type="confirmPassword"
            placeholder="Confirm Password"
            id="confirmPassword"
            name="confirmPassword"
          />

          <button
            type="submit"
            className="btn btn-primary btn-primary:hover btn-primary:focus btn-primary:disabled ml-auto "
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <AiOutlineLoading3Quarters className="animate-spin h-5 w-5" />
            ) : (
              "Send"
            )}
          </button>

          <div className="m-5">
            <label className="text-sm block font-bold mb-2 text-gray-400">
              Already have an account?{" "}
            </label>

            <Link
              className="text-sm block font-bold mb-2 text-blue-600 hover:underline"
              to={`/signin?navigate=${navigate}`}
            >
              Sign-In
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  </div>
);
}
